import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import englishFlag from '../assets/images/english-flag.webp';
import frenchFlag from '../assets/images/french-flag.webp';

const LanguageSelector = () => {
    const location = useLocation();
    const isEnglishActive = location.pathname === '/en';
    const isFrenchActive = location.pathname === '/fr';

    return (
        <div className="absolute top-4 right-24 z-20 cursor-pointer mt-2 flex space-x-2">
            <Link to="/en">
                <img
                    src={englishFlag}
                    alt="English"
                    className={`h-12 w-12 md:h-8 md:w-8 rounded-full cursor-pointer ${isEnglishActive ? 'opacity-50' : ''}`}
                />
            </Link>
            <Link to="/fr">
                <img
                    src={frenchFlag}
                    alt="Français"
                    className={`h-12 w-12 md:h-8 md:w-8 rounded-full cursor-pointer ${isFrenchActive ? 'opacity-50' : ''}`}
                />
            </Link>
        </div>
    );
};

export default LanguageSelector;
