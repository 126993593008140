import React, { Children, useLayoutEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faLightbulb, faCogs, faTools, faRocket } from '@fortawesome/free-solid-svg-icons';

function Bookmark(props) {
  const { className, children, icon, ...restProps } = props; // Ajouter icon ici
  return (
    <li {...restProps} className="timeline-item">
      {children}
    </li>
  );
}

function Timeline(props) {
  const { className, tip = true, children } = props;
  const tipClassName = tip ? 'with-tip' : '';
  const fullClassName = `timeline-wrapper ${tipClassName} ${className || ''}`;
  const numBookmarks = Children.count(children);

  useLayoutEffect(() => {
    let css = '';
    for (let i = 1; i <= numBookmarks; i += 1) {
      css += `.timeline-item:nth-child(${i}) {grid-row: ${i};}`;
    }

    document.head.insertAdjacentHTML(
      'beforeend',
      `<style data-timeline-styles>${css}</style>`
    );

    return () => {
      document.querySelector('[data-timeline-styles]').remove();
    };
  }, [numBookmarks]);

  return (
    <div className={fullClassName}>
      <div className="timeline-connector" />
      <ul className="timeline">
        {children}
        {Array(numBookmarks)
          .fill()
          .map((_, i) => (
            <span key={i} className="timeline-dotmark">
              <div className="timeline-dot">
                {/* Mettre l'icône ici pour chaque dot */}
                {React.Children.toArray(children)[i].props.icon}
              </div>
            </span>
          ))}
      </ul>
    </div>
  );
}

// Exemple d'utilisation
function App() {
  return (
    <Timeline>
      <Bookmark icon={<FontAwesomeIcon icon={faSearch} />} >
        <h1 className="text-2xl">Analyse des besoins</h1>
        <p>Nous commençons par comprendre vos objectifs et votre public cible.</p>
      </Bookmark>
      <Bookmark icon={<FontAwesomeIcon icon={faLightbulb} />} >
        <h1 className="text-2xl">Conception créative</h1>
        <p>Nous développons des maquettes et des wireframes pour donner vie à votre vision.</p>
      </Bookmark>
      <Bookmark icon={<FontAwesomeIcon icon={faCogs} />} >
        <h1 className="text-2xl">Développement</h1>
        <p>Notre équipe technique se charge de transformer les designs en un site fonctionnel.</p>
      </Bookmark>
      <Bookmark icon={<FontAwesomeIcon icon={faTools} />} >
        <h1 className="text-2xl">Tests et ajustements</h1>
        <p>Nous effectuons des tests rigoureux pour garantir la qualité et la performance du site.</p>
      </Bookmark>
      <Bookmark icon={<FontAwesomeIcon icon={faRocket} />} >
        <h1 className="text-2xl">Lancement et suivi</h1>
        <p>Une fois le site lancé, nous continuons à surveiller sa performance et à apporter des améliorations si nécessaire.</p>
      </Bookmark>
    </Timeline>
  );
}

export { Timeline, Bookmark, App };
