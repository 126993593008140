// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import Hero from './components/Hero';
// import HeroEnglish from './components/HeroEnglish';

// const App = () => {
//   return (
//     <div>
//     <Router>
//         <div>
//             <Hero/>
//         </div>
//     </Router>
// </div>
//   );
// };

// export default App;

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Hero from './components/Hero';
import HeroEnglish from './components/HeroEnglish';
import LanguageSelector from './components/LanguageSelector';

const App = () => {
  return (
    <Router>
      <div>
        <LanguageSelector />
        <Routes>
          <Route path="/en" element={<HeroEnglish />} />
          <Route path="/fr" element={<Hero />} />
          <Route path="/" element={<Hero />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;

