import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/criospulse_yellow.webp';

const FooterEn = () => {
  return (
    <footer className="bg-[#29426d] text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">

          {/* Logo on the left */}
          <div className="mb-4 md:mb-0 w-full md:w-1/5 text-left">
            <img src={logo} alt="CRIOSPULSE Logo" className="w-50 mb-4 md:w-30" />
          </div>

          <div className="mb-6 md:mb-0 w-full md:w-1/3 text-left">
            <h3 className="text-2xl mb-4 text-[#face30] font-bold">In brief</h3>
            <p className="text-justify">
            <span className="text-[#face30] font-bold">CRIOS PULSE</span>  is a service from<a href="https://criosconsulting.com" className="text-[#face30] hover:text-yellow-500 font-bold">CRIOS CONSULTING</a> specializing in the creation of innovative web solutions.
            We support companies in their digital transformation, offering tailor-made services to meet their specific needs.
            </p>
          </div>

          <div className="mb-6 md:mb-0 w-full md:w-1/3 text-left">
            <h5 className="text-2xl mb-4 text-[#face30] font-bold">Our services</h5>
            <p className="text-justify">
                We offer services ranging from the creation of showcase sites to the development of tailor-made web applications.
                Whether you're an NGO or a large corporation, we offer digital solutions tailored to your budget, with a strong commitment to quality.
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-400 my-10"></div>

        {/* Footer Bottom with Social Icons */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p>&copy; 2024 - CRIOSPULSE. All rights reserved.</p>
          {/* <div className="flex space-x-4">
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default FooterEn;
