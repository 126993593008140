import React from 'react';
import logopulse from '../assets/images/criospulse officiel.webp';

const Modal = ({ isOpen, onClose }) => {
    if (!isOpen) return null;
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            onClose(); // Fermer le modal après le défilement
        }
    };
    return (
        <div className="fixed inset-0 bg-custom-gray flex flex-col z-50">
        <div className="flex justify-between items-center p-4">
            <div className="text-white">
                <img src={logopulse} alt="Logo" className="mb-4 h-16" />
            </div>
            <button onClick={onClose} className="text-custom-blue text-4xl h-14 mr-2 mb-3">✖</button>
        </div>
        <div className="flex-grow flex flex-col items-center justify-center text-white">
            <ul className="text-center">
                <li className="py-2 text-4xl md:text-4xl text-custom-blue cursor-pointer" onClick={() => scrollToSection('home')}>Notre agence</li>
                <li className="py-2 text-4xl md:text-4xl text-custom-blue cursor-pointer" onClick={() => scrollToSection('methodologie')}>Notre méthodologie</li>
                <li className="py-2 text-4xl md:text-4xl text-custom-blue cursor-pointer" onClick={() => scrollToSection('sites')}>Sites web réalisés</li>
                <li className="py-2 text-4xl md:text-4xl text-custom-blue cursor-pointer" onClick={() => scrollToSection('tarifs')}>Nos tarifs</li>
                <li className="py-2 text-4xl md:text-4xl text-custom-blue cursor-pointer" onClick={() => scrollToSection('contact')}>Contactez-nous</li>
            </ul>
        </div>
    </div>

    );
};

export default Modal;
