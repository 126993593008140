import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import logo from '../assets/images/criospulse_yellow.webp';

const Footer = () => {
  return (
    <footer className="bg-[#29426d] text-white py-8">
      <div className="container mx-auto px-4">
        <div className="flex flex-col md:flex-row justify-between items-center">

          {/* Logo on the left */}
          <div className="mb-4 md:mb-0 w-full md:w-1/5 text-left">
            <img src={logo} alt="CRIOSPULSE Logo" className="w-50 mb-4 md:w-30" />
          </div>

          <div className="mb-6 md:mb-0 w-full md:w-1/3 text-left">
            <h3 className="text-2xl mb-4 text-[#face30] font-bold">En résumé</h3>
            <p className="text-justify">
            <span className="text-[#face30] font-bold">CRIOS PULSE</span> est un service de <a href="https://criosconsulting.com" className="text-[#face30] hover:text-yellow-500 font-bold">CRIOS CONSULTING</a> spécialisé dans la création de solutions web innovantes. 
              Nous accompagnons les entreprises dans leur transformation numérique, en offrant des services sur mesure qui répondent à leurs besoins spécifiques.
            </p>
          </div>

          <div className="mb-6 md:mb-0 w-full md:w-1/3 text-left">
            <h5 className="text-2xl mb-4 text-[#face30] font-bold">Nos services</h5>
            <p className="text-justify">
              Nous proposons des services allant de la création de sites vitrines au développement d'applications web sur mesure. 
              Que vous soyez une ONG ou une grande entreprise, nous offrons des solutions digitales adaptées à votre budget, avec un fort engagement sur la qualité.
            </p>
          </div>
        </div>

        {/* Divider */}
        <div className="border-t border-gray-400 my-10"></div>

        {/* Footer Bottom with Social Icons */}
        <div className="flex flex-col md:flex-row justify-between items-center">
          <p>&copy; 2024 - CRIOSPULSE. Tous droits réservés.</p>
          {/* <div className="flex space-x-4">
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="#" className="text-[#face30] hover:text-white transition">
              <FontAwesomeIcon icon={faLinkedinIn} size="2x" />
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
